var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogConfirmation',{attrs:{"persistente":true,"width":_vm.tagDialog === 'add' ? 850 : 500,"show":_vm.exibirDialogConfirmacao,"tag":_vm.tagDialog,"title":((_vm.tagDialog === 'delete' ? 'Desvincular' : 'Vincular') + " Profissão")},on:{"close":function($event){return _vm.fecharDialog()},"confirm":function($event){return _vm.confirmarDialog()}},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.tagDialog === 'add')?_c('div',[_c('v-autocomplete',{staticClass:"mt-5",attrs:{"label":"Profissões","item-text":"descricao","item-value":"id","outlined":"","return-object":"","no-data-text":"Profissão não encontrada","items":_vm.profissoesNaoVinculadas},model:{value:(_vm.profissaoSelecionada),callback:function ($$v) {_vm.profissaoSelecionada=$$v},expression:"profissaoSelecionada"}})],1):_vm._e(),(_vm.tagDialog === 'activate')?_c('p',{staticClass:"mt-5"},[_vm._v(" Deseja vincular a profissão "+_vm._s(_vm.profissaoSelecionada.descricao)+" à esta especialização? ")]):_vm._e(),(_vm.tagDialog === 'delete')?_c('p',{staticClass:"mt-5"},[_vm._v(" Deseja desvincular a profissão "+_vm._s(_vm.profissaoSelecionada.descricao)+" desta especialização? ")]):_vm._e()]},proxy:true}])}),_c('CrudTable',{attrs:{"tabelaKey":"id","headers":_vm.headers,"items":_vm.profissoesVinculadas,"pagination":_vm.pagination,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('ButtonMain',{attrs:{"primary":true,"text":"Vincular Profissão"},nativeOn:{"click":function($event){return _vm.abrirDialogVincularProfissao()}}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"white--text",attrs:{"small":"","color":"red","loading":item.atualizandoStatus,"disabled":item.atualizandoStatus},on:{"click":function($event){_vm.validarDesvinculação(item)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',[_vm._v("Desvincular Profissão")])])],1)]}},{key:"item.descricao",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.descricao))])]}}],null,true)}),(_vm.cadastrandoColaborador)?_c('div',{staticClass:"footer-cadastro"},[_c('ButtonMain',{staticClass:"primary mr-2",staticStyle:{"width":"10rem"},attrs:{"text":"Voltar"},nativeOn:{"click":function($event){return _vm.voltarPasso()}}}),_c('ButtonMain',{staticClass:"primary",staticStyle:{"width":"13rem"},attrs:{"text":"Concluir Cadastro"},nativeOn:{"click":function($event){return _vm.concluirCadastro()}}})],1):_vm._e(),(_vm.contextoComponente !== 'area-colaboradores')?_c('div',{staticClass:"footer-pagina"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"ml-3 mt-5",attrs:{"to":_vm.rotaDeVolta}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-left-thin ")]),_vm._v(" Voltar ")],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }