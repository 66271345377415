<template>
  <div>
    <v-tabs v-model="tab">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs" v-on="on"
            style="cursor: pointer"
            class="ml-5 mr-4"
            @click="$router.push('/especialidades')"
          >mdi-keyboard-backspace</v-icon>
      </template>
      <span>Voltar para lista de especialidades</span>
      </v-tooltip>

      <v-tabs-slider color="grey lighten-3"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.content">
          <v-container>
            <component
              class="mt-5"
              :is="item.component"
            />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosEspecialidade from './DadosEspecialidade.vue'
import ProfisoesEspecialidade from './ProfisoesEspecialidade.vue'

export default {
  components: {
    DadosEspecialidade,
    ProfisoesEspecialidade
  },

  data() {
    return {
      tab: null,
      profissaoId: null,
      items: [
        {
          tab: 'Dados',
          content: 'tab-dados-especialidade',
          component: 'DadosEspecialidade'
        },
        {
          tab: 'Profissões',
          content: 'tab-´profissoes-especialidade',
          component: 'ProfisoesEspecialidade'
        }
      ]
    }
  },
}
</script>

<style scoped>
.ajuste {
  width: 80%;
}
</style>
