<template>
  <v-container>
    <CrudTable
      tabelaKey="id"
      :tabelaComDetalhes="true"
      :headers="headers"
      :items="especializacoes"
      :loading="carregandoAcaoGenerica"
      :pagination="paginacao"
      :keyPaginacao="keyPaginacao"
      @actualPage="page"
      @clickRow="detalharEspecialidade($event)"
    >
      <template v-slot:top>
        <v-toolbar flat :class="$vuetify.breakpoint.xs ? 'scroll' : ''">
          <ButtonMain
            class="mr-2"
            :primary="true"
            text="Adicionar Especialidade"
            @click.native="dialogCadastro = true"
          />

          <v-spacer></v-spacer>

          <DialogConfirmation
            title="Cadastro de Especialidade"
            :scrollable="true"
            :width="600"
            :show="dialogCadastro"
            :key="keyDialog"
            :tag="'add'"
            @close="dialogCadastro = false"
            @confirm="cadastrarEspecialidade()"
          >
            <template v-slot:body>
              <v-form class="form-fields fields">
                <FormField :validate="keyFormValidacao" :fields="fields" />
              </v-form>
            </template>
          </DialogConfirmation>

        </v-toolbar>
      </template>

      <!-- <template v-slot:[`item.idStatus`]="{ item }">
        <StatusChip :color="getColor(item.idStatus)">
          {{ item.descricaoStatus ? item.descricaoStatus : 'NÃO INFORMADO' }}
        </StatusChip>
      </template> -->

      <!-- <template v-slot:[`item.acoes`]="{ item }">
        <v-row>
          <TooltipMain top text="Tornar Ativa">
            <ButtonIcon
              class="mx-1"
              :success="true"
              @click.native="editPublicação(item)"
              icon="mdi-check-circle"
            />
          </TooltipMain>
        </v-row>
      </template> -->
    </CrudTable>
  </v-container>
</template>

<script>
  import CrudTable from '@/components/molecules/tables/CrudTable'
  import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
  import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
  import TitleH2 from '@/components/atoms/title/TitleH2'
  import FormField from '@/components/atoms/forms/FormField.vue'

  import colaboradorService from '@/services/ColaboradorService'
  import utilService from '@/services/UtilService'


  export default {
    components: {
      CrudTable,
      DialogConfirmation,
      ButtonMain,
      TitleH2,
      FormField
    },

    data() {
      return {
        keyPaginacao: 0,
        keyFormValidacao: 0,
        keyDialog: 0,
        dialogCadastro: false,
        carregandoAcaoGenerica: false,

        paginacao: {
          page: 0,
          perPage: 10,
          total: 0
        },

        fields: {
          descricao: {
            label: 'Nome',
            valueField: null,
            value: '',
            cols: 12,
            field: 'InputField',
            rules: [value => !!value || 'Campo obrigatório']
          },
          profissao: {
            label: 'Profissões',
            valueField: null,
            value: null,
            cols: 12,
            field: 'SelectField',
            items: [],
            keyValue: 'id',
            keyName: 'descricao',
            clearable: false,
            multiple: true,
            rules: [(value) => !!value || 'Campo obrigatório']
          },
          observacao: {
            label: 'Observação',
            valueField: null,
            value: '',
            cols: 12,
            field: 'TextAreaField',
            maxLength: 150,
            rows: 4,
            rules: []
          },
        },

        especializacoes: [],
        headers: [
          { text: 'Nome', value: 'descricao', width: '30%', sortable: false },
          { text: 'Observação', value: 'observacao',  sortable: false},
          // { text: 'Ações', width: 200, align: 'start', value: 'acoes' }
        ],
      }
    },

    created() {
      this.receberEspecialidades();
      this.receberProfissoes();
    },

    methods: {
      receberProfissoes() {
        utilService.profissoes().then(({ data }) => {
          this.fields.profissao.items = data
        })
    },

      receberEspecialidades() {
        this.carregandoAcaoGenerica = true;

        const paginacao = { page: this.paginacao.page, size: 10 }
        colaboradorService.especialidades(paginacao)
          .then(({ data }) => {
            this.especializacoes = data.content.map(item => ({
              ...item,
              observacao: item.observacao || '[ Sem observação ]'
            }));

            this.paginacao.total = data.totalPages;
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          })
          .then(() => this.carregandoAcaoGenerica = false);
      },

      detalharEspecialidade(item) {
        this.$router.push({ name: 'DadosEspecialidade', params: { id: item.id } })
      },

      cadastrarEspecialidade() {
        if (this.verificarSeHaCampoObrigatorioNulo(this.fields)) {
          ++this.keyFormValidacao;
          this.$root.vtoast.show({
            status: 'error',
            title: 'Erro!',
            text: 'Dados Incompletos',
          });

          return;
        }

        const body = {
          area: 1,
          descricao: this.fields.descricao.value,
          observacao: this.fields.observacao.value,
          profissoes: this.fields.profissao.value,
        }

        this.carregandoAcaoGenerica = true;
        colaboradorService
          .cadastrarEspecialidade(body)
          .then(() => {
            this.carregandoAcaoGenerica = false
            this.receberEspecialidades();
            this.dialogCadastro = false
          })
          .catch((err) => {
            this.carregandoAcaoGenerica = false
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          })
      },

      verificarSeHaCampoObrigatorioNulo(campos) {
        let campoInvalido = false;
        for(const [key, value] of Object.entries(campos)) {
          const valorCampo = value.value;
          const regras = value.rules;
          let erroCampo = false;

          if(regras && regras.length) {
            for(const regra of regras) {
              if(regra(valorCampo) ===  'Campo obrigatório') {
                erroCampo = true;
                break;
              }
            }
          }

          if(erroCampo) {
            campoInvalido = true;
            break;
          };
        }

        return campoInvalido;
      },

      page(page) {
        this.paginacao.page = page - 1;
        this.receberEspecialidades()
      },
    },
  }

</script>

<style scoped>
</style>