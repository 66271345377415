<template>
  <div>
    <div class="main">
      <v-row>

        <DialogConfirmation
          tag="edit"
          title="Alterar Dados"
          :show="exibirDialogAvisoEdicao"
          @confirm="alterarDadosEspecialidade()"
          @close="exibirDialogAvisoEdicao = false"
        >
          <template v-slot:body>
            <div class="mt-5">
              Esta ação alterará os dados desta especialidade. Deseja continuar?
            </div>
          </template>
        </DialogConfirmation>

        <v-col offset-sm="12" md="9">
          <TitleH2 class="mt-3 mb-5 mx-3" title="Dados da Especialidade" />
          <FormField
            :fields="fields"
            :validate="validacao"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="ml-3" offset-sm="12" md="9">
          <ButtonMain
            class="primary mr-5 mb-5"
            style="width: 12rem"
            animacao="spin"
            :text="alterandoDados ?  'Alterando...' : 'Alterar'"
            :icone="alterandoDados ? 'autorenew' : ''"
            :desabilitar="alterandoDados"
            @click.native="exibirDialogAvisoEdicao = true"
          ></ButtonMain>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center" md="12" order-md="1">
          <div class="footer-pagina">
            <v-toolbar flat>
              <v-btn class="ml-3" :to="rotaDeVolta">
                <v-icon small>mdi-arrow-left-thin </v-icon>
                Voltar
              </v-btn>
            </v-toolbar>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import colaboradorService from '@/services/ColaboradorService'
import TitleH2 from '@/components/atoms/title/TitleH2.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'


export default {
  components: {
    TitleH2,
    FormField,
    ButtonMain,
    DialogConfirmation
  },

  data() {
    return {
      rotaDeVolta: '/especialidades',
      alterandoDados: false,
      exibirDialogAvisoEdicao: false,
      criandoFormulario: false,
      validacao: 0,
      especialidade: {},
      listaUfs: [],
      enderecoCep: [],
      fields: {
        descricao: {
          label: 'Nome',
          valueField: null,
          value: '',
          cols: 8,
          field: 'InputField',
          rules: []
        },
        observacao: {
          label: 'Observação',
          valueField: null,
          value: '',
          cols: 8,
          field: 'TextAreaField',
          maxLength: 150,
          rows: 4,
          rules: []
        },
      },
    }
  },

  created() {
    this.receberDadosEspecialidade();
  },

  methods: {
    receberDadosEspecialidade() {
      colaboradorService.especialiadePorId(this.$route.params.id)
        .then(({ data }) => {
          this.especialidade = data;
          this.criarFormulario(data);
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    criarFormulario(data) {
      this.criandoFormulario = true;
      Object.entries(data).map(([key, value]) => {
        if(this.fields[key]) {
          this.fields[key].valueField = value;
        }
      })
    },

    alterarDadosEspecialidade() {
      this.exibirDialogAvisoEdicao = false;

      this.alterandoDados = true;
      const body = {
        area: 1,
        descricao: this.fields.descricao.value,
        observacao: this.fields.observacao.value || null,
        profissao: null,
      };

      colaboradorService
        .alterarEspecialidade(this.especialidade.id, body)
        .then(() => {
          this.receberDadosEspecialidade();
          this.$root.vtoast.show({
            status: 'success',
            title: 'Sucesso!',
            text: 'Especialidade atualizada com sucesso.',
          });
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
        .then(() => this.alterandoDados = false)
    },
  },
}
</script>

<style scoped>
  .footer-pagina { margin-top: 2rem;}
</style>
